import Vue from 'vue'
import Router from 'vue-router'
import index from '@/view/index/index'
import line from '@/view/index/line'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: '首页',
      component: index,
      children: [
        { path: '/index', component: index, name: '主页' }
      ]
    },
    {
      path: '/line',
      name: '永平公交线路查询',
      component: line
    }
  ]
})
