<template>
  <div style="margin: 16px">
    <h3 style="text-align: center;">永平公交线路查询</h3>

    <h4>（一）、城区线。发车时间为6时50分，收班时间为19时。每车间隔时间为15分钟。</h4>

    <h4>往经站点分别为：</h4>

    <p>火车站——迤坝田岔口——曲硐新城 ——高速路口 ——大东门——曲硐大城门——振兴路口——摇头河——坡脚村岔路口——客运站路口
      ——县医院——博南文化园——思源实验学校——山水馨居 ——博南春天——城乡客运站——绿趣苑——交通局 ——霁虹宾馆——应急管理局——荷花小区
      ——农业局 ——市政广场 （共计23个站点）
    </p>

    <h4 style="margin-top: 32px;">返经站点分别为：</h4>

    <p>市政广场 ——退役军人事务局 ——梨花小区 ——住建局——霁虹宾馆——交通局——农贸市场 ——绿趣苑 ——城乡客运站 ——忆江南 ——山水馨居
      ——思源实验学校 ——博南文化园 ——县医院 ——交警队路口——万利豪庭 ——摇头河 ——振兴路口 ——曲硐大城门——大东门 ——高速路口 ——曲硐新城
      ——羊箐凹——迤坝田岔口——火车站 （共计25个站点）
    </p>

    <h4 style="margin-top: 32px;">（二）、学生专线。共有3条专线分别为：</h4>

    <h4>1号专线：早发车时间为：6时20分，晚发车时间为：20时50分，途径站点分别为：</h4>

    <p>老街中学岔口——北门口——祭祀塘——小河边—— 一中石牌坊——中医院——银江大桥——新光街（电信公司）——农贸市场门口——城乡客运站——忆江南——思源学校</p>

    <h4 style="margin-top: 32px;">2号专线：早发车时间为：6时20分，晚发车时间为：20时50分，途径站点分别为：</h4>

    <p>麦则山——河湾——妇幼保健院——车站路——荷花小区——市政广场——大营门小区——农贸市场门口——汇康医院——山水馨居——思源学校</p>

    <h4 style="margin-top: 32px;">3号专线：早发车时间为：6时25分，晚发车时间为：20时50分，途径站点分别为</h4>

    <p>摇头河（曲硐红绿灯）——苏屯——黑油关——新桥——逸品酒店——青少年活动中心——思源学校</p>
  </div>
</template>

<script>
export default {
  name: 'Line'
}
</script>

<style scoped>

</style>
