import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant'
import 'vant/lib/index.css'
import { Lazyload } from 'vant'
import router from './router'

Vue.config.productionTip = false
Vue.use(Vant)
Vue.use(Lazyload)
Vue.config.ignoredElements = [
  'wx-open-launch-weapp'
]
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
